import React from 'react'
import {withPrefix} from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../layouts";
import SEO from '../components/Seo';

// const Agenda = () => (
//   <React.Fragment>
//     <SEO title="Agenda"/>
//     <Helmet>
//       <html lang="en" />
//       <link type="text/css" rel="stylesheet" href={withPrefix(`/planner/sch.m2020europe.0.538.0.css`)} />
//       {/* <script src={withPrefix(`/planner/sch.m2020europe.0.538.0.js`)} /> */}
//     </Helmet>
//
//     <div id="root-schedule"/>
//   </React.Fragment>
//
// );


class Agenda extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = withPrefix(`/planner/sch.m2020europe.0.538.0.js?v2`);
    
    if (document.body) {
      document.body.appendChild(script);
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Agenda"/>
        <Helmet>
          <html lang="en" />
          <link type="text/css" rel="stylesheet" href={withPrefix(`/planner/sch.m2020europe.0.538.0.css`)} />
          {/* <script src={withPrefix(`/planner/sch.m2020europe.0.538.0.js`)} /> */}
        </Helmet>

        <div id="root-schedule"/>
      </Layout>
    )
  }
}

export default Agenda
